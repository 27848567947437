import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DataRoutes, Pages } from '../utils/routes.utils';
import { Container } from '@mui/material';
import HomePage from './homepage';
import Tabbar from '../components/tabbar.component';
import Programmation from './programmation.screen';
import About from './about.screen';

const Screens = React.memo(() => {

  return (
    <>
    {/* <Container style={{padding: 0, margin: 0, maxWidth: 'unset', height: '100%',
      background: 'linear-gradient(to bottom,#803EBF 0%,  #8644C3 30%,    #9E54D0 45%,    #B75EC6 50%,    #D15A3B 55%,    #E65D22 70%,    #FF621D 100%)',
    }}> */}
    <Container>
      <Tabbar routes={DataRoutes.routes}/>
      <Routes>
        {/* <Route path='*' element={<Navigate to={Pages.LANDING} replace />} /> */}
        <Route path='*' element={<Navigate to={Pages.HOMEPAGE} replace />} />
        {/* <Route element={<Landing/>} path={Pages.LANDING} /> */}
        {/* <Route path={Pages.HOME} element={<Home/>} /> */}
        <Route path={Pages.PROG} element={<Programmation/>} />
        <Route path={Pages.HOMEPAGE} element={<HomePage/>} />
        <Route path={Pages.ABOUT} element={<About/>} />
      </Routes>
    </Container>
    </>
  )
});

export default Screens;