import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ProgrammationDto } from '../store/programmation/programmation.models'
import { Colors } from '../constants/colors.constants';

import Arrow from '../assets/icons/Arrow.svg';
import ArrowYellow from '../assets/icons/ArrowYellow.svg';
import ArrowPurple from '../assets/icons/ArrowPurple.svg';

interface ProgCardProps {
  programmation: ProgrammationDto;
}

const ProgCard = (props: ProgCardProps) => {

  const { programmation } = props;

  return (
    <Stack 
      gap={2}
      style={{
        borderRadius: '20px', 
        // border: `1px solid ${Colors.folksPurple}`
        color: '#00FFFE',
        textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
                  boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent'

      }} 
      p={2} 
      width='100%'>
      <Stack direction={{xs: 'column', md: 'row'}} justifyContent='space-between'>
        <Typography 
          fontSize={20}>
          {programmation.date} - {programmation.location}
        </Typography>
        <Typography  fontSize={20}>{programmation.time}</Typography>
      </Stack>
      <Stack gap={1}>
        <Typography  fontFamily='DroulersClarendon-Line'>{programmation.types}</Typography>
        <Stack>
          {programmation.artists.map((it) => (
            <Stack direction='row' gap={1} flexWrap='nowrap'>
              <Typography 
                 
                fontSize={{xs: 16, md: 22}}>
                  {it.name}
              </Typography>
             {it.type && ( 
              <Typography 
                 
                fontSize={14} 
                fontFamily={'DroulersClarendon-Line'}>
                {`(${it.type})`}
              </Typography>)}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent={'space-between'} gap={{xs: 0, md: 2}}>
        <Typography>
          {programmation.price ? programmation.price : ''}
        </Typography>
        <Stack sx={{
          flex: 1, 
          backgroundColor: '#00FFFE', 
          height: '1px', 
          width: '100%', 
          boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
          visibility: { xs: 'hidden', md: 'visible'} }} />
        {programmation.link && (
          <Button
            onClick={() => {
              window.location.href = programmation.link || ''
            }}
            sx={{
              textTransform: 'uppercase',
              boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
              backgroundColor: '#00FFFE',
              color: '#803EBF',
              paddingLeft: '10px',
              paddingRight: '10px',
              borderRadius: '10px',
              xs: { width: '150px', maxWidth: '150px', fontSize: '14px' },
              md: { width: '250px', fontSize: '22px', maxWidth: '250px', minWidth: '250px' }
            }}
            >Réserver
          </Button>
          )}

      </Stack>
    </Stack>
  )
}

export default ProgCard;