import { createTheme } from '@mui/material';
import card from './card.theme';
import button from './button.theme';
import fonts from '../assets/fonts';

const theme = createTheme({
  typography: {
    fontFamily: 'DroulersClarendon-Line, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${fonts}
        html {
          height: 100%;
        }
        body {
          margin: 0;
          max-height: 100%;
          scrollbar-width: thin;
          overflow-x: hidden;
          background: linear-gradient(to bottom, #803EBF 0%, #8644C3 30%, #9E54D0 45%, #B75EC6 50%, #D15A3B 55%, #E65D22 70%, #FF621D 100%);
          background-attachment: fixed;
        }
        #root {
          height: 100%;
          white-space: pre-line;
        }
      `,
    },
  },
  ...card,
  ...button
});

export default theme;