import { useLocation } from 'react-router-dom';

export enum Pages {
  LANDING = 'landing',
  HOME = 'home',
  HOMEPAGE = 'home-page',
  PROG = 'programmation',
  ABOUT = 'about'
}

export namespace DataRoutes {
  export const routes: any[] = [
    { label: '', path: Pages.HOMEPAGE },
    { label: '', path: Pages.PROG },
    { label: '', path: Pages.ABOUT }
  ]
}


export const useRouteMatch = (patterns: readonly string[] = []) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];

    const possibleMatch = pathname.split("/").some((p) => {
      return p === pattern;
    });

    if (possibleMatch) {
      return pattern;
    }
  }

  return null;
};