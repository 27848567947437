import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Logo1 from '../assets/images/Folks_bonhomme1.svg';
import Logo2 from '../assets/images/Folks_bonhomme2.svg';
import Logo3 from '../assets/images/Folks_bonhomme3.svg';
import NeonHeader from '../assets/images/Folks_Marseille_dates.svg';


const HomePage = () => {

  const navigate = useNavigate();

  return (
    <Stack 
      alignItems='center' 
      gap={4} 
      pb={4} 
      style={{
        color: '#00FFFE',
        textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
    }}>
      <Stack gap={3} maxWidth='800px'>
        <Stack justifyContent='center' alignItems='center' p={4} gap={2}>
          <img src={NeonHeader} alt='' style={{maxHeight: '300px'}} />
          <Typography fontSize={{xs: 18, md: 22}} align='center' className='blueHighlight'>
          Folks revient pour une nouvelle édition, une oreille toujours tournée vers les musiques traditionnelles et l’autre vers la création contemporaine. <br/><br/>

          Des concerts, discussions et soirées clubs où s'entremêlent instruments traditionnels, chants contestataires et basses fréquences dans une 
          exploration hypnotique comme une ouverture à l’autre. En questionnant nos passés et nos sonorités modernes, Folks s’engage à convoquer les 
          histoires et les terroirs pour sublimer ces artistes qui donnent du sens aux dancefloors.
          </Typography>
        </Stack>
        <Stack direction='row' gap={1} sx={{display: {xs: 'none', md: 'flex'}}}>
          <img src={Logo1} alt='' style={{height: '180px'}} />
          <img src={Logo2} alt='' style={{height: '180px'}} />
          <img src={Logo3} alt='' style={{height: '180px'}} />
        </Stack>
        <Stack style={{
          borderRadius: '20px', 
          boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent'
          }} 
          p={3} 
          m={2}>
          <Typography fontSize={{xs: 16, md: 28}} align='center'>
          Espace Julien - Le petit 51<br/>La Mûrisserie - L'Embobineuse<br/>Meta
          </Typography>
        </Stack>
      </Stack>
      <Stack width='100%' p={6} alignItems='center' mt={4} position='relative'>
        <Stack direction='row' position='absolute' top={'-20px'} gap={2}>
        <div style={{backgroundColor: `#00FFFE`, boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent', height: '20px', width: '20px', borderRadius: '20px'}}></div>
          <div style={{backgroundColor: `#00FFFE`, boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent', height: '20px', width: '20px', borderRadius: '20px'}}></div>
          <div style={{backgroundColor: `#00FFFE`, boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent', height: '20px', width: '20px', borderRadius: '20px'}}></div>
        </Stack>
        <Stack maxWidth='700px' alignItems='center' justifyContent='center'>
          <Typography fontSize={{xs: 20, md: 30}} align='center'>
            CLARA! & RRITA JASHARI (Live)<br/>
            DAVID BOLA (Talk)<br/>
            DJ HIDRATACCIONI<br/>
            DJ STARTUP (Live)<br/>
            EMMAUNELLE CARINOS VASQUEZ (Talk)<br/>
            FLEGON (Live)<br/>
            FRANSSOUAX<br/>
            KSU (Ambiant set)<br/>
            MALESA (Live)<br/>
            MONA HKL (Dj set commenté)<br/>
            NENĬA IRĂ (Live)<br/>
            ROSTANGA<br/>
            SHLAGGA<br/>
          </Typography>
        </Stack>
        <Stack direction='row' position='absolute' bottom={'-20px'} gap={2}>
          <div style={{backgroundColor: `#00FFFE`, boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent', height: '20px', width: '20px', borderRadius: '20px'}}></div>
          <div style={{backgroundColor: `#00FFFE`, boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent', height: '20px', width: '20px', borderRadius: '20px'}}></div>
          <div style={{backgroundColor: `#00FFFE`, boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent', height: '20px', width: '20px', borderRadius: '20px'}}></div>
        </Stack>
      </Stack>
      <Button
        onClick={() => navigate('/programmation')}
        style={{
          textTransform: 'uppercase',
          boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
          backgroundColor: '#00FFFE',
          color: '#803EBF',
          fontSize: '22px',
          paddingLeft: '10px',
          paddingRight: '10px',
          borderRadius: '10px',
          marginTop: '3rem',
          width: '300px'
        }}
        >Programmation</Button>
      <Button
        onClick={() => {
          window.location.href='mailto:contact.folksfestival@gmail.com'
        }}
        style={{
          paddingTop: '15px',
          textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
          textDecoration: 'underline',
          color: '#00FFFE',
          fontSize: '16px',
          paddingLeft: '10px',
          paddingRight: '10px',
          borderRadius: '10px',
          width: '300px'
        }}>{`Nous contacter`}</Button>
    </Stack>
  )
}

export default HomePage;