import React from 'react';
import { Typography, Stack, Button } from '@mui/material';

import Logo1 from '../assets/images/Folks_bonhomme1.svg';
import Logo2 from '../assets/images/Folks_bonhomme2.svg';
import Logo3 from '../assets/images/Folks_bonhomme3.svg';

import Poster2024 from '../assets/images/Folks_Affiche.jpg'

const About = () => {
  return (
    <Stack 
      alignItems='center' 
      gap={4} 
      pt={4} 
      pb={4}
      p={3}
      style={{
        color: '#00FFFE',
        textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
    }}>
       <Stack direction='row' gap={1} sx={{display: {xs: 'none', md: 'flex'}}}>
          <img src={Logo1} alt='' style={{height: '180px'}} />
          <img src={Logo2} alt='' style={{height: '180px'}} />
          <img src={Logo3} alt='' style={{height: '180px'}} />
        </Stack>
      <Stack gap={5} maxWidth='800px' alignItems='center'>
        <Typography 
          fontSize={{xs: 18, md: 22}} 
          align='center'>
          Folks Festival est le projet évènementiel phare de l’association Folks, créée en mars 2023 et basée à Marseille. 
          Il se déroule pendant la première quinzaine d’avril et investit plusieurs lieux marseillais dans le but de 
          mettre en lumière des projets artistiques situés à la jonction entre musiques traditionnelles et création 
          contemporaine.<br/>
          Sa première édition s’est déroulée du 3 au 12 avril 2024, avec 4 événements qui se sont tenus dans 
          des lieux emblématiques du paysage culturel de la ville : l’Espace Julien, l’Embobineuse, 
          l’Espace Projet Club Coop et les Ateliers Jeanne Barret. <br/>
          À cette occasion, 1200 festivalièr·es ont pu assister aux performances de 22 artistes, venu·es de 
          Marseille et d’au-delà, dont les propositions artistiques ont mobilisé des héritages musicaux multiples. 
          Traditions musicales de la région MENA, inspirations bretonnes ou occitanes, ou encore héritages néo-folk 
          ont ainsi rencontré des sous-genres des musiques actuelles et électroniques.<br/>
          Forte de ce premier succès, l’association présentera du 2 au 12 avril 2025 la seconde édition de son festival. 
          Des concerts, discussions et soirées clubs où s'entremêlent instruments traditionnels, chants contestataires 
          et basses fréquences dans une exploration hypnotique comme une ouverture à l’autre. <br/>
          En questionnant nos passés et nos sonorités modernes, Folks s’engage à convoquer les histoires 
          et les terroirs pour sublimer ces artistes qui donnent du sens aux dancefloors. 
        </Typography>
        <Button
          onClick={() => {
            window.location.href='mailto:contact.folksfestival@gmail.com'
          }}
          style={{
            paddingTop: '15px',
            textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
            textDecoration: 'underline',
            color: '#00FFFE',
            fontSize: '16px',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '10px',
            width: '300px'
          }}>{`Nous contacter`}</Button>
       <Typography
          fontSize={{xs: 14, md: 18}} 
          style={{fontFamily: 'DroulersClarendon-Line', fontWeight: 700}}
          align='center'>
          Folks remercie chaleureusement l’ensemble des partenaires et ami·es qui rendent possible 
          l’existence du festival : le collectif de designers graphiques Super Terrain qui a réalisé 
          l’affiche de cette édition, les équipes des lieux qui accueillent nos événements 
          (Espace Julien, l’Embobineuse, La Mûrisserie, Le Petit 51, Meta), Camille-Saint Yves, 
          Bérengère Calbris, Agathe Coubard, et tou·tes les bénévoles pour leur aide précieuse !
        </Typography>
        <div 
          style={{
            height: '1px', 
            width: '90%',
            margin: '2rem',
            boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent'
            }}/>
        <Stack gap={3} alignItems='center'>
          <Typography fontSize={30}>Précédentes éditions</Typography> 
          <Stack maxWidth={{xs: '100%', md: '400px'}}>
            <img src={Poster2024} alt='' style={{maxWidth: '100%'}} />
          </Stack>
          <Typography fontSize={26}>
          3 → 12 avril 2024
          </Typography>
          <Stack style={{
            borderRadius: '20px', 
            border: '1px',
            boxShadow: 'inset 0 0 5px #00FFFE, inset 0 0 10px #00FFFE, inset 0 0 20px #00FFFE, inset 0 0 80px transparent, 0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent'
            }} 
            p={3}>
            <Typography fontFamily='DroulersClarendon-Line' fontSize={{xs: 10, md: 16}} align='center'>
            Ateliers Jeanne Barret, Embobineuse<br/>Espace Julien, Espace Projet Club Coop
            </Typography>
          </Stack>
          <Typography textAlign='center' fontSize={22}>
            Emmanuelle Parrenin – Caïn و Muchi – Desastre – 
            Ksu b2b Trae Joly – Lugal Lanbada – Maquis Son Sistèm – 
            Mona HKL – Mystique – Paloma Colombe – Renaud Brizard – 
            René Danger – Syqlone
          </Typography>
        </Stack>
      </Stack>
  </Stack>
  )
}

export default About