import React from 'react';
import { Stack } from '@mui/material';
import { ProgrammationDto } from '../store/programmation/programmation.models'
import ProgCard from '../components/progCard.component';

const Programmation = () => {

  const prog: ProgrammationDto[] = [
    { 
      date: 'Mercredi 2 avril 2025', 
      location: 'Espace Julien', 
      types: '[LIVE]',
      artists: [
        { name: 'Clara! & Rrita Jashari', type: 'live' },
        { name: 'DJ Startup', type: 'live' },
      ],
      price: 'Gratuit sur réservation', 
      link: 'https://billetterie.espace-julien.com/agenda/870-l-ej-c-est-le-s-x-folks-festival?session=870',
      time: '20h30-00h30'
    },
    { 
      date: 'Dimanche 6 avril 2025', 
      location: "Le petit 51 - Cours Julien",
      types: '[DJ SETS]',
      artists: [
        { name: 'David Bola', type: 'dj set' },
        { name: 'Mona HKL', type: 'dj set commenté' },
        { name: 'KSU', type: 'ambient set' },
        { name: 'Rostanga', type: 'dj set' }
      ],
      price: 'Entrée prix libre', 
      link: 'https://www.helloasso.com/associations/folks-festival/evenements/folks-au-petit-51-david-bola-ksu-mona-hkl-et-rostanga',
      time: '16h00-20h00'
    },
    { 
      date: 'Mardi 8 avril 2025', 
      location: 'La Mûrisserie', 
      types: '[TALKS]',
      artists: [
        { name: 'Les musicien·nes contre le fascisme : identité, mémoire et émancipation avec David Bola et Emmanuelle Carinos Vasquez', type: 'talks' },
      ],
      price: 'Entrée prix libre', 
      link: 'https://www.helloasso.com/associations/folks-festival/evenements/folks-a-la-murisserie-talks',
      time: '18h30-20h30'
    },
    { 
      date: 'Vendredi 11 avril 2025', 
      location: "L'Embobineuse", 
      types: '[LIVES, DJ SETS]',
      artists: [
        { name: 'Flegon', type: 'live' },
        { name: 'Nenĭa Iră', type: 'live' },
        { name: 'Shlagga' },
      ],
      price: '10€ + 2€ adhésion', 
      link: 'https://www.helloasso.com/associations/folks-festival/evenements/flegon-nenia-ira-shlagga',
      time: '21h00-2h00'
    },
    { 
      date: 'Samedi 12 avril 2025', 
      location: "Meta", 
      types: '[LIVES, DJ SETS]',
      artists: [
        { name: 'DJ Hidrataccioni', type: 'dj set' },
        { name: 'Franssouax', type: 'dj set' },
        { name: 'Malesa', type: 'live' },
        { name: 'Rostanga', type: 'dj set' }
      ],
      price: 'Billetterie à venir', 
      // link: 'https://www.helloasso.com/associations/folks-festival/evenements/folks-soiree-de-cloture',
      time: '00h00-06h00'
    }
  ]

  return (
    <Stack alignItems='center' justifyContent='center' gap={4} pt={4} pb={4} minWidth='80%'>
      <Stack gap={4} minWidth='80%' maxWidth='90%' alignItems='center' justifyContent='center'>
        {prog.map((it) => (
          <ProgCard programmation={it} />
        ))}
      </Stack>
    </Stack>
  )
}

export default Programmation;