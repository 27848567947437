import React, { useState } from 'react';
import { Stack, Button, IconButton } from '@mui/material';
import { Pages, useRouteMatch } from '../utils/routes.utils';
import { useNavigate } from 'react-router-dom';

import Instagram from '../assets/icons/instagram.svg';

interface TabbarProps {
  routes: { label: string, path: string }[];
  handleChange?: () => void;
}

const Tabbar = (props: TabbarProps) => {

  const { routes, handleChange } = props;

  const currentTab = useRouteMatch(routes.map((route) => route.path));

  const navigate = useNavigate();

  return (
    <Stack 
      direction='row' 
      alignItems='center'
      padding={{xs: 2, sm: 3, md: 4}}
      justifyContent='center' 
      gap={1}
      display={"flex"}
      style={{
        textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent !important',
      }}
      >
      <Stack direction='row' position={{xs: 'relative', sm: 'absolute'}} sx={{left: {sm: '20px'}}} gap={1}>
        <IconButton 
          onClick={() => {
            window.location.href = 'https://www.instagram.com/folksfestivalmarseille/'
          }} 
          style={{
            height: '30px', 
            width: '30px'
          }}>
          <img src={Instagram} alt='' 
          style={{
            height: '30px', 
            width: '30px'
          }}/>
        </IconButton>
      </Stack>
      <Button 
        onClick={() => navigate('/homepage')}
        sx={{
          fontSize: { xs: '12px', md: '16px' },
          textDecoration: currentTab === Pages.HOMEPAGE ? 'underline' : 'none',
          textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
          color: '#00FFFE'
        }}>
        Accueil
      </Button>
      <Button 
        onClick={() => navigate('/programmation')}
        sx={{
          fontSize: { xs: '12px', md: '16px' },
          textDecoration: currentTab === Pages.PROG ? 'underline' : 'none',
          textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
          color: '#00FFFE'
        }}>
        Programmation & billetterie
      </Button>
      <Button 
        onClick={() => navigate('/about')}
        sx={{
          fontSize: { xs: '12px', md: '16px' },
          textDecoration: currentTab === Pages.ABOUT ? 'underline' : 'none',
          textShadow: '0 0 5px #00FFFE, 0 0 10px #00FFFE, 0 0 20px #00FFFE, 0 0 80px transparent',
          color: '#00FFFE'
        }}>
        À propos
      </Button>
    </Stack>
  )
}

export default Tabbar;