import React, { useEffect, useState } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';

// const MouseHalo = () => {
//   const [position, setPosition] = useState({ x: 0, y: 0 });

//   useEffect(() => {
//     const handleMouseMove = (e: MouseEvent) => {
//       setPosition({ x: e.clientX, y: e.clientY });
//     };

//     window.addEventListener('mousemove', handleMouseMove);
//     return () => window.removeEventListener('mousemove', handleMouseMove);
//   }, []);

//   return (
//     <motion.div
//       style={{
//         position: 'fixed',
//         top: position.y,
//         left: position.x,
//         width: '80px',
//         height: '80px',
//         borderRadius: '50%',
//         background: 'radial-gradient(circle, rgba(0, 255, 254, 0.6) 0%, transparent 70%)',
//         pointerEvents: 'none', // Ignore les clics
//         // transform: 'translate(-50%, -50%)', // Centre le halo sur le curseur
//         mixBlendMode: 'soft-light', 
//         zIndex: 9999,
//       }}
//       animate={{
//         x: position.x - 100,  // Correction pour centrer sur le curseur
//         y: position.y - 100,  // Correction pour centrer sur le curseur
//       }}
//       transition={{
//         duration: 0.8,
//         delay: 0,
//         ease: [0, 0.71, 0.2, 1.01],
//       }}
//     />
//   );
// };

const MouseHalo = () => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  // Animation fluide avec spring
  const springX = useSpring(x, { stiffness: 100, damping: 15 });
  const springY = useSpring(y, { stiffness: 100, damping: 15 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      x.set(e.clientX -  100);
      y.set(e.clientY - 100);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [x, y]);

  return (
    <motion.div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '200px',
        height: '200px',
        pointerEvents: 'none',
        borderRadius: '50% 40% 60% 50% / 40% 50% 60% 50%',
        background: 'radial-gradient(circle, rgba(0, 255, 254, 0.9) 0%, rgba(0, 255, 254, 0.4) 0%, transparent 30%)',
        // background: 'radial-gradient(circle at 50% 50%, rgba(0, 255, 254, 0.6) 0%, transparent 70%, transparent 100%)',

        zIndex: 9999,
        x: springX,   // Animation contrôlée par le spring
        y: springY,   // Animation contrôlée par le spring
        // transform: 'translate(-50%, -50%)', // Centre bien sur le curseur
      }}
    />
  );
};

export default MouseHalo;